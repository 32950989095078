import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/Video"
import { graphql } from "gatsby"
const AdditionalVideos = ({ data }) => {
  return (
    <Layout>
      <SEO title="Additional Videos" />
      <section id="contact">
        <div className="container">
          <section className="p-0" id="portfolio">
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-lg-10 mx-auto">
                  <h3 className="text-uppercase sr-video text-center">
                    <a
                      href="https://www.youtube.com/playlist?list=PLbcOniKgao80utL11GOoLxXGDKD4dCGAX"
                      target="_blank"
                    >
                      Link to Mike's latest videos for families at home
                    </a>
                  </h3>
                  <br />
                  <h3 className="text-uppercase sr-video text-center">
                    <strong>Mike one-on-one</strong>
                  </h3>
                  <hr className="sr-1" />
                </div>
                <div className="col-lg-6 sr-1" style={{ padding: "20px" }}>
                  <Video
                    url="FWg0UT-OfaQ"
                    img={data.oneOnOnePt1.childImageSharp.fluid}
                  ></Video>
                </div>

                <div className="col-lg-6 sr-1" style={{ padding: "20px" }}>
                  <Video
                    url="9nOLFmmc3yM"
                    img={data.oneOnOnePt2.childImageSharp.fluid}
                  ></Video>
                </div>

                <div className="col-lg-10 mx-auto">
                  <h3 className="text-uppercase sr-1 text-center">
                    <strong>Mike with an audience</strong>
                  </h3>
                  <hr className="sr-1" />
                </div>

                <div className="col-lg-6 sr-1" style={{ padding: "20px" }}>
                  <Video
                    url="8pEaZdCssU0"
                    img={data.audiencePt1.childImageSharp.fluid}
                  ></Video>
                </div>

                <div className="col-lg-6 sr-1" style={{ padding: "20px" }}>
                  <Video
                    url="EbiIdrVaVsI"
                    img={data.audiencePt2.childImageSharp.fluid}
                  ></Video>
                </div>

                <div className="col-lg-10 mx-auto">
                  <h3 className="text-uppercase sr-1 text-center">
                    <strong>Mike in the press</strong>
                  </h3>
                  <hr className="sr-1" />
                </div>

                <div className="col-lg-6 sr-1" style={{ padding: "20px" }}>
                  <Video
                    url="Y7OQGyQUez0"
                    img={data.mediaPt1.childImageSharp.fluid}
                  ></Video>
                </div>
                <div className="col-lg-6 sr-1" style={{ padding: "20px" }}>
                  <Video
                    url="Tg4EadS1zSs"
                    img={data.mediaPt2.childImageSharp.fluid}
                  ></Video>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export default AdditionalVideos

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    oneOnOnePt1: file(relativePath: { eq: "additional-videos/clapping.png" }) {
      ...fluidImage
    }
    oneOnOnePt2: file(
      relativePath: { eq: "additional-videos/high-fives.png" }
    ) {
      ...fluidImage
    }
    audiencePt1: file(
      relativePath: { eq: "additional-videos/sumthing-amazing.png" }
    ) {
      ...fluidImage
    }
    audiencePt2: file(relativePath: { eq: "assembly.png" }) {
      ...fluidImage
    }
    mediaPt1: file(
      relativePath: { eq: "additional-videos/windy-city-live.png" }
    ) {
      ...fluidImage
    }
    mediaPt2: file(relativePath: { eq: "additional-videos/tedx.png" }) {
      ...fluidImage
    }
  }
`
